<!-- eslint-disable vue/no-unused-vars -->
<template>
  <v-container id="regular-tables-view" fluid tag="section">
    <progress-spinner :isLoading="loading" />
    <v-toolbar-title>Appointment Type Assignments</v-toolbar-title>
    <br>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-data-table
            :headers="headers"
            :items="appointmentTypesWithEmployees"
            sort-by="Name"
            item-key="Id"
            class="elevation-1 row-pointer"
            @click:row="handleRowClick"
          >
            <template v-slot:item.assignedTo="{ item }">
              <div class="d-flex flex-wrap">
                <v-chip class="ma-1" v-for="(item, index) in item.assignedTo" :key="index">
                  {{item}}
                </v-chip>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <div class="py-3" />
  </v-container>
</template>

<script>
const axios = require("axios");
export default {
  name: "AppointmentType",
  data() {
    return {
      loading: false,
      page: 1,
      pageSize: 10,
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "Name",
        },
        { text: "Skill", value: "Skill" },
        { text: "Assigned to", value: "assignedTo" }
      ],
      appointmentTypesWithEmployees: [],
      appointmentTypes: []
    };
  },

  methods: {
    handleRowClick(item) {
      this.$router.push({ name: 'Appointment Details', params: item });
    },

    async getAppointmentTypes() {
      this.loading = true;
      this.appointmentTypes = [];
      try {
        let { data } = await axios.get(`appointments/types`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        this.appointmentTypes = data;
      } catch (err) {
        this.$errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async getAppointmentTypesWithEmployees(appointmentTypeIds) {
      this.loading = true;
      this.appointmentTypesWithEmployees = [];
      try {
        let { data } = await axios.post(`appointments/types/with-employees`, {appointmentTypeIds}, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        this.appointmentTypesWithEmployees = data;
        this.appointmentTypesWithEmployees.forEach((item) => {
          item.Employees.forEach((item) => item.Name = `${item.FirstName} ${item.LastName}`);
        })
        this.appointmentTypesWithEmployees.forEach((item) => item.assignedTo = item.Employees.map((item) => item.Name));

      } catch (err) {
        this.$errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    await this.getAppointmentTypes();
    await this.getAppointmentTypesWithEmployees(this.appointmentTypes.map((item) => item.Id));
  }
};
</script>
<style>
tbody tr :hover {
  cursor: pointer;
}
</style>
